import React from 'react'
import Button from 'react-bootstrap/Button'
import DpGrid from './DpGrid'
import { useAuth0 } from "./react-auth0-spa"
import Admin from './Admin'
import logo from './Logo500.png'

// build
// New - import the React Router components, and the Profile page component
// import { BrowserRouter, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Profile from "./components/Profile";

let accessToken
let role

function Index() {
  const {loading, loginWithPopup, user, getTokenSilently, isAuthenticated, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();

  if (loading) {
    return "Loading...";
  }
  if (isAuthenticated) {
        role = user['https://dpweb.hogandata.com/app_metadata'].role
  }
  return (
    <div className="App">
      { !isAuthenticated && (
        <div>
          <img src={logo} style={  { display: 'block', margin: '65px auto', height: '15vh'} } alt="Logo" />
         <Button className="login-btn" variant="outline-primary" onClick={ () => { loginWithRedirect({}) }}>login</Button>
        </div>
      )
        // <Button className="mr-0" variant="outline-primary" onClick={ async () => { await auth0.loginWithPopup(); console.log(user); console.log(getIdTokenClaims({})); } } > </Button>
      }
      { isAuthenticated && (
        <div>
          { ['administrator', 'root'].includes(role.toLowerCase()) && <Link className="admin-btn" to="/admin/">Manage</Link> }
          <button id="logout" className="logout-btn btn" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
          <DpGrid dpRole={role} tokenFn={getTokenSilently}/>
        </div>
        )
      }
    <div style={ { position: 'fixed', right: '0', left: '0', bottom: '5px', 'text-align': 'center', 'font-size': '11px', color: 'gray' } }>© 2018 – 2020 Hogan Data. All rights reserved</div>
    </div>
  )
}

function App() {

return (
  <Router>
    <Route path="/" exact component={Index} />
    <Route path="/admin/" render={ (props) => <Admin {...props} accessToken={ accessToken } /> } />
  </Router>
  )
}
export default App
