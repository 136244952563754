import React, {
  Component
} from 'react';
import {
  AgGridReact
} from 'ag-grid-react';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Badge from 'react-bootstrap/Badge'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
 
//import createAuth0Client from 'auth0-spa-js'


class DpGrid extends Component {
  constructor(props) {
    super(props);
    this.viewStates = {
      'init': {
        parentMenuItems: [],
      }
    } // this holds data for different menu group views
    this.state = {
      activeView: 'init',
      parentMenuItems: [],
      childMenuItems_t: [],
      rowData_t: [],
      datasourceStack: [{menuItems: [], rowData: []}], // used for bottom pane to provide history nav
      // currentDatasource: 0,
      currentIdx: 0,
      panelState: 1,
    }
  }

  componentDidMount() {
    this.props.tokenFn().then(t => {
      this.init(t)
    })
  }

  extPropsMap = {}

  //db_api = process.env.REACT_APP_SERVICES_URL || 'http://ec2-54-159-17-50.compute-1.amazonaws.com:3001' // 'http://localhost:3001'
  db_api = process.env.REACT_APP_SERVICES_URL || 'http://localhost:3001'

  // this.updateViewStates({rowData_t: rowData, childMenuItems_t: menuItems})
  updateViewStates = (newState) => {
    const vsu = { ...newState }
    delete vsu.activeView
    const av = newState.activeView ? newState.activeView : this.state.activeView
    this.viewStates[av] = { ...this.viewStates[av], ...vsu }
    const cvs = this.viewStates[av] //currentViewState
    this.setState((prevState, props) => ({
      parentMenuItems: cvs.parentMenuItems,
      childMenuItems_t: cvs.childMenuItems_t,
      selectedMenuItem_t: cvs.selectedMenuItem_t,
      rowData_t: cvs.rowData_t,
      datasourceStack: cvs.datasourceStack,
      currentIdx: cvs.currentIdx,
      activeView: av,
    }))
  }

  init = (token) => {
    const url = `${this.db_api}`;
    fetch(url, {headers: { Authorization: `Bearer ${token}`}})
      .then(result => result.json())
      .then(data => {
        this.viewStates = data.menuItems.reduce((agg, mi) => {
          if (!agg[mi.groupName]) {
            agg[mi.groupName] = {
              parentMenuItems: [],
              childMenuItems_t: [],
              selectedMenuItem_t: '',
              rowData_t: [],
              datasourceStack: [{menuItems: [], rowData: []}], // used for bottom pane to provide history nav
              // currentDatasource: 0,
              currentIdx: 0,
            }
          }
          agg[mi.groupName].parentMenuItems.push(mi)
          return agg
        }, {})
        const defaultView = Object.keys(this.viewStates)[0]
        this.updateViewStates({
          activeView: defaultView,
          // parentMenuItems: this.viewStates[defaultView].parentMenuItems,
        })

        this.setState((prevState, props) => ({
          activeView: defaultView, 
          parentMenuItems: this.viewStates[defaultView].parentMenuItems,
          accessToken: token
        }))
      })
  }

  updateUpper = ({ rowData, menuItems, extendedProperties }, viewName) => {
    this.extPropsMap[viewName] = extendedProperties
    this.updateViewStates({rowData_t: rowData, childMenuItems_t: menuItems, selectedMenuItem_t: viewName})
    this.gridApi_t.hideOverlay()
    this.columnApi_t.autoSizeColumns(this.columnApi_t.getAllColumns().map(c => c.colId), true)
    if (this.extPropsMap[viewName].ColumnSort) {
      this.gridApi_t.setSortModel(this.extPropsMap[viewName].ColumnSort.split(',').map(c => ({colId: c.substring(c.indexOf('[') + 1, c.indexOf(']')), sort: c.substring(c.lastIndexOf(' ') + 1)})))
    }
    //this.setState((prevState, props) => ({
    //  rowData_t: rowData ? rowData : prevState.rowData_t,
    //  childMenuItems_t: menuItems ? menuItems : prevState.childMenuItems_t,
    //}))
  }

  updateLower = menuItem => clearHistory =>  ({ rowData, menuItems, extendedProperties }) => {
		const menuItemName = menuItem.displayName
		if(!this.extPropsMap[menuItemName]) {
    	this.extPropsMap[menuItemName] = extendedProperties
			
		}
    const dsS = this.state.datasourceStack
    if (clearHistory) {
      dsS.length = 0
    }
    dsS.push({menuItemName, rowData, menuItems})
    const idx = dsS.length - 1 < 0 ? 0 : dsS.length - 1
    this.setState((prevState, props) => ({
      datasourceStack: dsS,
      currentIdx: idx,
    }))
    this.gridApi_b.hideOverlay()
    this.columnApi_b.autoSizeColumns(this.columnApi_b.getAllColumns().map(c => c.colId), true)
    if (this.extPropsMap[menuItemName].ColumnSort) {
      this.gridApi_b.setSortModel(this.extPropsMap[menuItemName].ColumnSort.split(',').map(c => ({colId: c.substring(c.indexOf('[') + 1, c.indexOf(']')), sort: c.substring(c.lastIndexOf(' ') + 1)})))
    }
  }

  nav = (d) => {
    const newIdx = this.state.currentIdx + d
    if ( newIdx < 0 || newIdx >= this.state.datasourceStack.length) {
      return
    }
    this.setState((x, y) => ({ currentIdx: newIdx }))
  }
  
  getExtPropValue = (extProp, colName) => {
    if (extProp) {
      return extProp.split(',').find(p => p.substring(p.indexOf('[') + 1, p.indexOf(']')) === colName)
    }
    return null
  }
  getFormatter = (viewName, colName) => {
    if (!this.extPropsMap[viewName]) {
      return null
    }
    if (this.getExtPropValue(this.extPropsMap[viewName].Money, colName)) {
      //Money Formatter
      return (params) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(params.value)
    }
    if (this.getExtPropValue(this.extPropsMap[viewName].DateOnly, colName)) {
      return (params) => params.value ? new Date(params.value).toLocaleDateString("en-US") : ''
		}
    if (this.getExtPropValue(this.extPropsMap[viewName].TimeOnly, colName)) {
      return (params) => params.value ? new Date(params.value).toLocaleTimeString("en-US") : ''
		}
    if (this.getExtPropValue(this.extPropsMap[viewName].Percentage, colName)) {
      return (params) => params.value ? params.value + ' %' : ''
		}
    if (this.getExtPropValue(this.extPropsMap[viewName].YesNo, colName)) {
      return (params) => params.value ? 'Yes' : 'No'
		}
    if (this.getExtPropValue(this.extPropsMap[viewName].ColumnPrecision, colName)) {
      return (params) => Number.parseFloat(params.value).toFixed(this.getExtPropValue(this.extPropsMap[viewName].ColumnPrecision, colName).split(':')[1])
		}
		return null
  }

  getCellRenderer = (viewName, colName) => {
    if (!this.extPropsMap[viewName]) {
      return null
    }
    if (this.extPropsMap[viewName].Hyperlink && this.extPropsMap[viewName].Hyperlink.includes(colName)) {
      return (params) => `<a href="${params.value}" target="_blank" rel="noopener">${params.value}</a>`
    }
    return null
  }

  getFilter = (viewName, colName) => {
    if (!this.extPropsMap[viewName]) {
      return true
    }
    if (this.getExtPropValue(this.extPropsMap[viewName].NumberFilter, colName)) {
      return 'agNumberColumnFilter'
    }
    else if (this.getExtPropValue(this.extPropsMap[viewName].DateFilter, colName)) {
      return 'agDateColumnFilter'
    }
    return true;
  }

  getColumnDefs = (recordset, checkbox, viewName) => {
    if (!recordset) {
     return []
    }
    let coldef;
    if (recordset.length > 0) {
      coldef = Object.keys(recordset[0]).map(r => {
        return {
          headerName: r,
          field: r,
          sortable: true,
          filter: this.getFilter(viewName, r),
          valueFormatter: this.getFormatter(viewName, r),
          cellRenderer: this.getCellRenderer(viewName, r),
					hide: (this.extPropsMap[viewName] && this.extPropsMap[viewName].Hidden && this.extPropsMap[viewName].Hidden.includes(r)) || (this.extPropsMap[viewName].AdminOnly && this.extPropsMap[viewName].AdminOnly.includes(r) && !['Administrator', 'root'].includes(this.props.role))
        }
      })
      coldef[0].checkboxSelection = checkbox ? true : false;
      return coldef;
    }
    return [];
  }

  getViewKeys = gridApi => viewKey => () => {
    const unique = {}
    gridApi.forEachNode((node, index) => {
      unique[node.data[viewKey]] = true
    })
    return Object.keys(unique)
  }

  getAllViewData = view => callback => e => {
    this.gridApi_t.showLoadingOverlay()
    const viewId = view.id
      const url = `${this.db_api}/${viewId}`
      fetch(url, {headers: { Authorization: `Bearer ${this.state.accessToken}`}})
        .then(result => result.json())
        .then(data => {
          callback(data, view.displayName)
        })
  }
  // below is support for infinite scroll
  // getAllViewData = (view, queryParams) => callback => e => {
  //   this.gridApi_t.showLoadingOverlay() // can prolly axe this!!!!!!
  //   const viewId = view.id
  //     const url = `${this.db_api}/${viewId}`
  //     fetch(url, {headers: { Authorization: `Bearer ${this.state.accessToken}`}})
  //       .then(result => result.json())
  //       .then(data => {
  //         callback(data, view.displayName)
  //       })
  // }

  infiniteScrollDatasource = (urlBase, accessToken) => (view) => (updateUpperCallback) => {
    return {
      getRows(getRowsParams) {
        console.log('urlBase', urlBase)
        console.log('viewid', view.id)
        console.log('getRowsParams', getRowsParams)

        const fetchUrl = `${urlBase}/${view.id}/${getRowsParams.startRow}`
        fetch(fetchUrl, {headers: { Authorization: `Bearer ${accessToken}`}})
        .then(result => result.json())
        .then(data => {
          getRowsParams.successCallback(data.rowData, data.lastRow);
          updateUpperCallback(data, view.displayName)
        })
      }
    }
    
  }

  getViewData = viewId => getValFn => callback => e => {
    this.gridApi_b.showLoadingOverlay()
    const vals = getValFn()
    // only query if params
    if (vals.length > 0) {
      const url = `${this.db_api}/${viewId}/${vals.join('%2C')}`
      console.log('fetch url', url)
    fetch(url, {headers: { Authorization: `Bearer ${this.state.accessToken}`}})
        .then(result => result.json())
        .then(data => {
          callback(data)
        })
    }
    else {
      callback({rowData: [], menuItems: []})
    }
  }

  onSelectionChanged = e => {
    var selectedRows = this.gridApi_t.getSelectedRows();
  }

  render() {
      return (
        <div>
          <div className="header" style={ {} }><span class="title"><h5>DataPlus cloud - Office Interiors</h5></span></div>
        <div className = "container" style={ {height:'calc(100vh - 45px)', 'max-width':'90vw', 'min-width':'400px'} } >
          <div className={"row panel" + (this.state.panelState === 0 ? ' panel-hide' : this.state.panelState === 2 ? ' panel-full' : '')}>
            <div className="col top-contents">
              <ButtonToolbar className="drill-down-bar">
                <DropdownButton  aria-label="First group" title={ this.state.activeView }>
                  {
                    Object.keys(this.viewStates).map(k =>
                      <Dropdown.Item key={`fgb-id-${k}`}
                        className="mr-0"
                        variant="primary"
                        onClick={
                          () => { this.updateViewStates({ activeView: k}) }
                        }
                      >
                        {k}
                      </Dropdown.Item>
                    )
                  }
                </DropdownButton>
                <DropdownButton // ------------------------------------------[ MAIN MENU ]------------------------------------------
                  className="drill-down"
                  id="parent-menu"
                  variant="primary"
                  title="Parent Menu">
                {
                  this.state.parentMenuItems.map(d =>
                    <Dropdown.Item
                      key={ d.id }
                      onClick={ this.getAllViewData(d)(this.updateUpper) }
                    //  onClick={ () => {
                    //    this.gridApi_t.setDatasource(
                    //      this.infiniteScrollDatasource(this.db_api, this.state.accessToken)(d)(this.updateUpper)
                    //    )
                    //  } }
                  >
                      { d.displayName }
                    </Dropdown.Item>
                  )

                  // this.state.parentMenuItems.map(d => < Dropdown.Item key = { d.id } data-viewkey = { d.key } data-viewid = { d.id } data-targetgrid="top" onClick ={ this.drillDown(this.gridApi_t.getSelectedNodes().map(x => { return x.data[d.key] })) }>{ d.displayName }</Dropdown.Item>)
                }
                </DropdownButton>
                <DropdownButton // ------------------------------------------[ TOP DRILLDOWN ]------------------------------------------
                  id="parent-drilldown"
                  variant="primary"
                  title="Drill Down"
                  disabled = { this.state.childMenuItems_t.length < 1 ? true : false }>
                {
                  this.state.childMenuItems_t.filter(x => x).map(d => // XXX - filter out null
                    <Dropdown.Item
                      key={ d.id }
                      onClick={
                        this.getViewData(d.id)( () => { return this.gridApi_t.getSelectedNodes().sort((a,b) => { if(a.displayName < b.displayName) return -1; if (a.displayName > b.displayName) return 1; return 0; }).map(x => { return x.data[d.key] }) })(this.updateLower(d)(true))
                      }
                    >
                      { d.displayName }
                    </Dropdown.Item>
                  )
                }
                </DropdownButton>
                
                <Button variant="primary" disabled={ !this.state.rowData_t || !this.state.rowData_t.length } onClick={ () => this.gridApi_t.exportDataAsCsv() }><FontAwesomeIcon icon={faFileExport} /></Button>
                <div className="ml-auto pr-2"><Badge id="xyz"  variant="primary">{this.state.selectedMenuItem_t}</Badge></div>
              </ButtonToolbar>
              <div className = "ag-theme-balham" style={ { height: 'calc(100% - 38px)' } }>
                  <AgGridReact
                    onGridReady={ params => { this.gridApi_t = params.api; this.columnApi_t = params.columnApi; }}
                    defaultColDef= {{ resizable: true }}
                    columnDefs = { this.getColumnDefs(this.state.rowData_t, true, this.state.selectedMenuItem_t) }
                    pagination = { true }
                    paginationAutoPageSize={ false }
                    rowSelection = 'multiple'
                    onSelectionChanged = { this.onSelectionChanged }
                    rowData = { this.state.rowData_t } 
                    //infinite scroll support below
                    //rowModelType = 'infinite'
                  >
                  </AgGridReact>
              </div>
            </div>
          </div>
          <div className="divider" >
            <FontAwesomeIcon className="text-secondary" style={ { cursor: 'pointer' } } icon={faChevronCircleUp} onClick={ (e) => { e.preventDefault(); const ps = this.state.panelState; if (ps > 0) this.setState(() => ({panelState: ps - 1})) }} /> 
            { this.state.panelState < 2 && (
              <div>
              <hr />
                <FontAwesomeIcon className="text-secondary" style={ { cursor: 'pointer' } } icon={faChevronCircleDown} onClick={ () => { if (this.state.panelState < 2) this.setState((prev) => ({panelState: this.state.panelState + 1})) }} /> 
              </div>
            )}
          </div >
          <div className={"row panel" + (this.state.panelState === 2 ? ' panel-hide' : this.state.panelState === 0 ? ' panel-full' : '')}>
            <div className="col">
              <ButtonToolbar className="drill-down-bar">
                <DropdownButton // ------------------------------------------[ BOTTOM DRILLDOWN }---------------------------------------
                  id="child-drilldown"
                  variant="primary"
                  title="Drill Down"
                  disabled={ this.state.datasourceStack[this.state.currentIdx].menuItems.length < 1 ? true : false }>
                {
                  this.state.datasourceStack[this.state.currentIdx].menuItems.sort((a,b) => { if(a.displayName < b.displayName) return -1; if (a.displayName > b.displayName) return 1; return 0; }).map(d =>
                    <Dropdown.Item
                      key={ d.id }
                      onClick={
                        // this.drillDown(this.getViewKeys(this.gridApi_b)(d.key))
                        this.getViewData(d.id)(this.getViewKeys(this.gridApi_b)(d.key))(this.updateLower(d)())
                      }
                    >
                      { d.displayName }
                  </Dropdown.Item>)
                }
                </DropdownButton>
                <Button
                  id="history-bak"
                  variant="primary"
                  disabled={ this.state.currentIdx > 0 ? false : true }
                  onClick={ () => {this.nav(-1)} }
                >&lt;
                </Button>
                <Button
                  id="history-fwd"
                  variant="primary"
                  disabled={ this.state.currentIdx < this.state.datasourceStack.length - 1 ? false : true }
                  onClick={ () => { this.nav(1) } }>&gt;
                </Button>
                <Button variant="primary" disabled={ !this.state.datasourceStack[this.state.currentIdx].rowData.length } onClick={ () => this.gridApi_b.exportDataAsCsv() }><FontAwesomeIcon icon={faFileExport} /></Button>
                <div className="ml-auto pt-2 pr-2"><Badge id="xyz"  variant="primary">{this.state.datasourceStack[this.state.currentIdx].menuItemName}</Badge></div>
              </ButtonToolbar>
              <div className = "ag-theme-balham" style={ { height: 'calc(100% - 38px)' } }>
                  <AgGridReact
                    onGridReady={ params => { this.gridApi_b = params.api; this.columnApi_b = params.columnApi; }}
                    defaultColDef= {{ resizable: true }}
                    columnDefs = { this.getColumnDefs( this.state.datasourceStack[this.state.currentIdx].rowData, false, this.state.datasourceStack[this.state.currentIdx].menuItemName ) }
                    pagination = { true }
                    rowSelection='single'
                    paginationAutoPageSize={ false }
                    onSelectionChanged = { this.onSelectionChanged }
                    rowData = { this.state.datasourceStack[this.state.currentIdx].rowData } >
                  </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
  }
}

export default DpGrid;
