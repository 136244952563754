import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import {
  AgGridReact
} from 'ag-grid-react';
import { useAuth0 } from "./react-auth0-spa"


const svcApi = process.env.REACT_APP_SERVICES_URL || 'http://localhost:3001'

let accessToken
let gridApi

function Admin(props) {

  //console.log('accessToken', accessToken)
  const {loading, loginWithPopup, user, getTokenSilently, isAuthenticated, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();
  const [validated, setValidated] = useState(false);
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [activeRole, setActiveRole] = useState(0)
  const [views, setViews] = useState([])
  const [addUser, setAddUser] = useState(0)
  const [adminManage, setAdminManage] = useState(0)
  const [userSelected, setUserSelected] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      createUser(form.formBasicEmail.value, form.formBasicPassword.value, form.formBasicRole.value)
    }
  };

  const getUsers = (tkn) => {
    fetch(`${svcApi}/users`, {headers: { Authorization: `Bearer ${tkn}`}})
      .then(res => res.json())
      .then(d => {
        setUsers(d)
      })
  }

  const getRoles = (tkn) => {
    fetch(`${svcApi}/admin/roles`, {headers: { Authorization: `Bearer ${tkn}`}})
      .then(res => res.json())
      .then(d => {
        setRoles(d)
      })
  }

  const getViews = (tkn) => {
    fetch(`${svcApi}/admin/views`, {headers: { Authorization: `Bearer ${tkn}`}})
      .then(res => res.json())
      .then(d => {
        setViews(d)
      })
  }

  const createUser = (email, password, role) => {
    fetch(`${svcApi}/user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, role }),
    })
    .then(r => r.json())
    .then(res => {
      if (res.statusCode > 399) {
        alert('create user failed: ' + res.message)
      }
      else {
        getUsers(accessToken)
        setAddUser(false)
      }
    })
    .catch(err => {
      alert('create user error ' + err)
    })
  }

  const deleteUser = (userId) => {
    fetch(`${svcApi}/user`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    })
    .then(res => {
      if (res.statusCode > 399) {
        alert('delete user failed: ' + res.message)
      }
      else {
        console.log('delete user api call response code', res.statusCode)
        getUsers(accessToken)
        gridApi.deselectAll()
        //setAddUser(false)
      }
    })
    .catch(err => {
      alert('create user error ' + err)
    })
  }

  const saveRolePerm = r => {
    fetch(`${svcApi}/role`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(r),
    })
    .then(r => r.json())
    .then(res => {
      if (res.statusCode > 399) {
        alert('role update failed: ' + res.message)
      }
      else {
        // XXX - may refresh from DB but should be synced with state unless failure.
      }
    })
    .catch(err => {
      console.log('save role error ' + err)
    })
  }

  const userSelectionChanged = () => {
    console.log(!!gridApi.getSelectedRows().length)
    setUserSelected(!!gridApi.getSelectedRows().length)
  }


  if (loading) {
    return "Loading...";
  }
  if (!isAuthenticated || !['administrator', 'root'].includes(user['https://dpweb.hogandata.com/app_metadata'].role.toLowerCase())) {
    return 'NOT AUTHENTICATED'
  }
  else if (!users.length) {
    getTokenSilently().then( tkn => {
      accessToken = tkn
      getUsers(tkn)
      getRoles(tkn)
      getViews(tkn)
    })
  }
  return (
    <div className="container" style={ {width: 'fit-content' } }>
      <div style={ {'textAlign': 'center', padding: '18px 0 20px 0' } }>
        <h2>Admin</h2>
      </div>
      <Link className="home-btn" to="/">Home</Link>
      { adminManage === 0 && (<Link className="admin-manage" onClick={ () => { setAdminManage(1) } }>Manage Roles</Link>) }
      { adminManage === 1 && (<Link className="admin-manage" onClick={ () => { setAdminManage(0) } }>Manage Users</Link>) }
      { !!addUser && adminManage === 0 && (
        <Form className="admin-add" noValidate validated={validated} onSubmit={ handleSubmit }>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control required type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control required type="password" placeholder="Password" />
          </Form.Group>
          <Form.Group controlId="formBasicRole">
            <Form.Label>Role</Form.Label>
            <Form.Control as="select" required type="role">
              {
                roles.map(r => (<option>{r.role}</option>))
              }
          </Form.Control>
          </Form.Group>
          <ButtonGroup style={ { width: '100%', margin: 'auto 0' } }>
            <Button variant="primary" type="submit">Create User</Button>
            <Button variant="secondary" onClick={ () => {setAddUser(0)} }>Cancel</Button>
          </ButtonGroup>
        </Form>
      ) }
      { !addUser && adminManage === 0 && (
        <div className = "ag-theme-balham" style={ { width: '500px', height: '55vh'} }>
          <AgGridReact
            onGridReady={ params => gridApi = params.api }
            defaultColDef= {{ resizable: true }}
            columnDefs = { [ { headerName: 'Email', field: 'email', checkboxSelection: true}, { headerName: 'Role', field: 'app_metadata.role'}] }
            pagination = { true }
            paginationAutoPageSize={ true }
            rowSelection = 'single'
            rowData = { users } 
            onSelectionChanged = { userSelectionChanged } >
          </AgGridReact>
          <Button variant="primary" onClick={ () => {setAddUser(1)} }>Add User</Button>
          &nbsp;&nbsp;<Button disabled={ !userSelected }variant="danger" onClick={ () => { deleteUser(gridApi.getSelectedRows()[0]['user_id']) } }>Delete</Button>
        </div>
      ) }
      { adminManage === 1 && ( <div className="row justify-content-around">
        <div className="col-4">
          <ListGroup className="role-list">
            {
              roles.map((r, idx) => <ListGroup.Item variant={ idx === activeRole ? 'primary' : '' } action onClick={ ()=>{ setActiveRole(idx); console.log('active role', activeRole) } }>{r.role}</ListGroup.Item>)
            }
          </ListGroup>
          <br />
          <Button variant="primary" onClick={ () => { saveRolePerm(roles[activeRole]) } }>Save</Button>
        </div>
        <div className="col-8" style={ {'max-height': '75vh', overflow: 'auto'} }>
          <ListGroup className="role-list">
            {
              views.map(v => <ListGroup.Item variant={ roles.length && roles[activeRole].viewIds && roles[activeRole].viewIds.includes(v.id.toString()) ? 'primary' : '' } action onClick={ ()=> {
                if (roles[activeRole].viewIds.includes(v.id.toString())) {
                  const newRoles = roles.slice(0)
                  newRoles[activeRole].viewIds = roles[activeRole].viewIds.filter(i => i !== v.id.toString() )
                  setRoles(newRoles)
                }
                else {
                  const newRoles = roles.slice(0)
                  newRoles[activeRole].viewIds.push(v.id.toString())
                  setRoles(newRoles)
                }
              }}>{v.displayName}</ListGroup.Item>)
            }
          </ListGroup>
        </div>
      </div>
      ) }
    </div>
  )
}

export default Admin
