import React from 'react'
import ReactDOM from 'react-dom'
//import "./index.css"; // XXX maybe DELETE!!
//import App from './App'
import App from './App'
//import * as serviceWorker from "./serviceWorker"; // XXX - wtf
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
//import 'bootstrap/dist/css/bootstrap.css';

// A function that routes the user to the right place
// after login

if (crypto && !crypto.subtle) { // XXX!!! - hack for chrome issue. might be cuz url's not localhost need to be ssl
  crypto.subtle = {digest:  () => {}}
}

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    audience={config.audience} 
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();


// ReactDOM.render( <App /> , document.getElementById("root")) XXX - old stuff maybe del.
